import React from "react"
import { ColorScheme, rem } from "@mantine/core"

export function Logo({
    size,
    colorScheme,
}: {
    size: number
    colorScheme: ColorScheme
}) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 186 165"
                width={rem(size)}
            >
                <circle
                    cx="93"
                    cy="90"
                    r="70"
                    stroke={colorScheme === "dark" ? "#fff" : "#000"}
                    strokeWidth="3"
                />
                <g
                    transform="translate(0.000000,184.000000) scale(0.100000,-0.100000)"
                    fill={colorScheme === "dark" ? "#fff" : "#000"}
                >
                    <path
                        d="M838 1440 c-56 -17 -162 -112 -180 -161 -9 -26 -12 -27 -75 -23 -89
4 -98 -6 -98 -106 0 -42 5 -83 11 -91 6 -8 14 -26 17 -40 9 -34 59 -97 103
-127 48 -32 137 -41 194 -18 53 21 51 22 34 -19 -20 -48 -19 -184 2 -213 13
-19 24 -22 83 -22 61 0 70 3 84 23 8 13 16 46 18 73 3 57 47 158 63 145 21
-17 91 -22 121 -10 17 7 35 13 42 15 31 6 86 65 114 122 23 48 33 84 38 144 7
77 6 80 -18 100 -21 16 -38 19 -99 16 l-74 -3 -19 37 c-35 69 -115 136 -185
156 -46 13 -135 15 -176 2z m183 -35 c74 -25 169 -121 169 -169 0 -24 -156 -9
-193 18 -35 26 -108 27 -135 3 -14 -13 -38 -17 -100 -17 -75 0 -82 2 -82 20 0
30 99 124 150 143 58 20 135 21 191 2z m-176 -182 c149 -3 331 -7 405 -7 l135
-1 3 -50 c7 -101 -59 -233 -133 -270 -64 -31 -125 -29 -177 5 -48 32 -103 101
-113 143 -9 34 -23 44 -31 20 -26 -82 -87 -156 -140 -169 -14 -4 -53 -4 -85
-1 -49 4 -67 11 -102 40 -66 54 -114 173 -101 251 8 54 10 56 42 51 15 -3 149
-8 297 -12z m135 -242 c0 -5 21 -29 46 -54 l46 -46 -33 -41 c-30 -37 -34 -50
-37 -116 l-4 -75 -71 3 -70 3 2 83 c3 100 2 100 60 184 43 63 61 81 61 59z"
                    />
                    <path
                        fill={colorScheme === "dark" ? "#fff" : "#000"}
                        d="M610 1214 c0 -3 16 -26 36 -52 20 -26 58 -93 86 -149 28 -57 55 -103
60 -103 14 0 68 40 68 50 0 5 -11 26 -25 46 -30 44 -31 59 -5 81 11 10 20 24
20 33 0 22 -58 90 -77 90 -11 0 -7 -7 11 -21 45 -35 51 -57 26 -91 l-22 -30
26 -51 c25 -50 25 -51 6 -64 -27 -19 -38 -10 -65 51 -13 28 -41 78 -64 111
-50 74 -52 92 -8 98 22 3 15 4 -20 5 -29 1 -53 -1 -53 -4z"
                    />
                    <path
                        fill={colorScheme === "dark" ? "#fff" : "#000"}
                        d="M1057 1188 c4 -7 25 -39 46 -70 20 -32 54 -92 74 -135 20 -43 38 -80
40 -82 4 -6 62 20 73 35 9 10 5 24 -14 57 -31 53 -33 73 -6 87 29 15 25 35
-16 80 -29 32 -43 40 -68 39 -27 -2 -28 -2 -6 -6 34 -7 77 -39 83 -63 3 -11
-3 -30 -14 -44 -24 -31 -24 -41 2 -82 24 -39 18 -74 -12 -74 -15 0 -27 15 -44
53 -13 28 -44 83 -70 121 -25 39 -43 76 -41 83 3 8 -2 13 -15 13 -14 0 -17 -4
-12 -12z"
                    />
                    <path
                        fill={colorScheme === "dark" ? "#fff" : "#000"}
                        d="M860 577 c-23 -12 -25 -19 -28 -91 -2 -60 0 -77 10 -74 9 3 13 27 14
74 l1 69 72 2 71 3 0 -75 0 -75 -74 0 c-72 0 -104 -11 -70 -24 25 -10 137 -7
154 4 8 5 11 10 6 10 -5 0 -4 6 2 13 5 6 10 40 10 75 1 83 -16 102 -90 102
-29 0 -64 -6 -78 -13z"
                    />
                </g>
            </svg>
        </>
    )
}
